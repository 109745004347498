import { ITaxProviderGrouping, ITaxProviderInfo } from './../../pages/hcp/users/create/index.models'
import { createSlice, Draft } from '@reduxjs/toolkit'
import axios from '../../utilities/axios'
import { IUser, INPILocationsData, IEmailValidation, IAreaCodeResult } from '../../pages/hcp/users/create/index.models'
import { addToast } from './toast'
import { successCheckmark } from 'components/Toast/icons'
import { dispatch } from 'redux/store'
import { EToastVariant } from 'models/IToast'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'

export type UserState = {
    users: IUser[]
    usersRequestId: string | null
    usersLoading: boolean
    newProvider: boolean
    newOfficeManager: boolean
    details: any
    role: any
    taxInfo: ITaxProviderInfo
    npiInfo: INPILocationsData
    emailInfo: IEmailValidation
    validAreaCodes: IAreaCodeResult
    isValidNPI: any
    totalNumberOfUsers: number
    filteredNumberOfUsers: number
    isClearValidation: boolean
}

const initialState: UserState = {
    users: [],
    usersRequestId: null,
    usersLoading: false,
    details: {},
    role: '',
    newProvider: false,
    newOfficeManager: false,
    taxInfo: { taxId: '', providers: [], error: false, errorMessage: '' },
    npiInfo: { npi: '', locations: [], error: false, errorMessage: '' },
    emailInfo: { emailExists: false },
    validAreaCodes: { areaCodes: [] },
    isValidNPI: { npiIsValid: true, providerWithNpiAlreadyExists: false },
    totalNumberOfUsers: 0,
    filteredNumberOfUsers: 0,
    isClearValidation: false
}

const updateUser = (state: Draft<UserState>, userId: string, updateRecord: (oldUser: IUser) => IUser): boolean => {
    const userIndex = state.users.findIndex((x) => x.id === userId)
    if (userIndex >= 0) {
        const users = [...state.users]
        users[userIndex] = updateRecord(users[userIndex])
        state.users = users
        return true
    } else {
        return false
    }
}

const updateOrAddUser = (state: Draft<UserState>, user: IUser) => {
    const updated = updateUser(state, user.id, (_oldUser) => user)
    if (!updated) {
        state.users = [user, ...state.users]
        state.totalNumberOfUsers = state.totalNumberOfUsers || 1
        state.filteredNumberOfUsers = state.filteredNumberOfUsers || 1
    }
}

const setUserState = (state: Draft<UserState>, userId: string, isActive: boolean) => {
    const userState = isActive ? 'Active' : 'Disabled'
    updateUser(state, userId, (user) => ({ ...user, isActive, state: userState }))
    if (state.details?.id === userId) {
        state.details = { ...state.details, isActive, state: userState }
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.users = []
            state.usersRequestId = null
            state.usersLoading = false
            state.details = {}
            state.role = ''
            state.newProvider = false
            state.newOfficeManager = false
            state.taxInfo = { taxId: '', providers: [], error: false, errorMessage: '' }
            state.npiInfo = { npi: '', locations: [], error: false, errorMessage: '' }
            state.emailInfo = { emailExists: false }
            state.validAreaCodes = { areaCodes: [] }
            state.isValidNPI = { npiIsValid: true, providerWithNpiAlreadyExists: false }
            state.totalNumberOfUsers = 0
            state.filteredNumberOfUsers = 0
            state.isClearValidation = false
        },
        clearUserDetails: (state) => {
            state.details = {}
            state.npiInfo = { npi: '', locations: [], error: false, errorMessage: '' }
        },
        clearNPIValidations: (state) => {
            state.isValidNPI = { npiIsValid: true, providerWithNpiAlreadyExists: false }
        },
        clearValidations: (state) => {
            state.isClearValidation = true
        },
        updateReportCount: (state) => {
            const currCount = state.details.newReportCount
            state.details.newReportCount = currCount > 0 ? currCount - 1 : 0
        },
        setNewReportCount: (state, action) => {
            if (state.details?.id === action.payload.userId) {
                state.details = { ...state.details, newReportCount: action.payload.newReportCount }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                state.usersRequestId = action.meta.requestId
                state.usersLoading = true
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                if (state.usersRequestId === action.meta.requestId) {
                    state.users = action.payload.users
                    state.usersLoading = false
                    state.totalNumberOfUsers = action.payload.totalNumberOfUsers
                    state.filteredNumberOfUsers = action.payload.filteredNumberOfUsers
                }
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                if (state.usersRequestId === action.meta.requestId) {
                    state.usersLoading = false
                }
            })
            .addCase(createProvider.fulfilled, (state, action) => {
                updateOrAddUser(state, action.payload)
            })
            .addCase(updateProvider.fulfilled, (state, action) => {
                updateOrAddUser(state, action.payload)
            })
            .addCase(createOfficeManager.fulfilled, (state, action) => {
                updateOrAddUser(state, action.payload)
                state.newOfficeManager = true
            })
            .addCase(updateOfficeManager.fulfilled, (state, action) => {
                updateOrAddUser(state, action.payload)
                state.newOfficeManager = true
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.details = removeHyphensFromTaxIds(action.payload)
            })
            .addCase(disableUser.fulfilled, (state, action) => {
                setUserState(state, action.meta.arg.userId, false)
            })
            .addCase(enableUser.fulfilled, (state, action) => {
                setUserState(state, action.meta.arg.userId, true)
            })
            .addCase(resendInvitation.fulfilled, (state, action) => {
                if (state.details?.id === action.meta.arg.UserId) {
                    state.details = {
                        ...state.details,
                        invitationSent: action.payload.invitationSent,
                        invitationExpired: false,
                        daysToInvitationExpiry: null
                    }
                }
                updateUser(state, action.meta.arg.UserId, (user) => ({
                    ...user,
                    invitationSent: action.payload.invitationSent,
                    invitationExpired: false,
                    daysToInvitationExpiry: null
                }))
            })
            .addCase(getNpisFromTaxId.fulfilled, (state, action) => {
                state.taxInfo = action.payload ?? { taxId: '', providers: [], error: false, errorMessage: '' }
            })
            .addCase(getValidAreaCodes.fulfilled, (state, action) => {
                state.validAreaCodes = action.payload ?? []
            })
            .addCase(doesEmailAlreadyExist.fulfilled, (state, action) => {
                state.emailInfo = action.payload ?? { emailExists: false }
            })
            .addCase(getLocationsFromNpi.fulfilled, (state, action) => {
                state.npiInfo = action.payload ?? { npi: '', locations: [], error: false, errorMessage: '' }
            })
            .addCase(validateNPI.fulfilled, (state, action) => {
                state.isValidNPI = action.payload
            })
    }
})

export const {
    clearUserDetails,
    clearNPIValidations,
    clearValidations,
    resetUser,
    updateReportCount,
    setNewReportCount
} = userSlice.actions
export default userSlice.reducer

export const fetchUsers = createExceptionAwareAsyncThunk('user/getUsers', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/Users', args)
    return response.data
})

export const createProvider = createExceptionAwareAsyncThunk(
    'user/createUser',
    async (args: any) => {
        const response = await axios.post('api/CallCenterAdmin/CreateProvider', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'New user created successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
            return response.data.user
        }
    },
    (error) => {
        error.setShowModal(false)
        dispatch(
            addToast({
                message: 'Provider was not created successfully',
                icon: '',
                time: 3000,
                variant: EToastVariant.ERROR
            })
        )
    }
)

export const updateProvider = createExceptionAwareAsyncThunk(
    'user/updateProvider',
    async (args: any) => {
        const response = await axios.post('api/CallCenterAdmin/UpdateProvider', args)
        if (response.status === 200) {
            dispatch(
                addToast({
                    message: 'User Info edited successfully',
                    icon: successCheckmark,
                    time: 3000,
                    variant: EToastVariant.SUCCESS
                })
            )
            return response.data
        }
    },
    (error) => {
        error.setShowModal(false)
        dispatch(
            addToast({
                message: 'User was not updated',
                icon: '',
                time: 3000,
                variant: EToastVariant.ERROR
            })
        )
    }
)

export const updateOfficeManager = createExceptionAwareAsyncThunk(
    'user/updateOfficeManager',
    async (args: any) => {
        const response = await axios.post('api/CallCenterAdmin/UpdateOfficeManager', args)

        dispatch(
            addToast({
                message: 'Admin updated successfully',
                icon: successCheckmark,
                time: 3000,
                variant: EToastVariant.SUCCESS
            })
        )

        return response.data
    },
    (error) => {
        error.setShowModal(false)
        dispatch(
            addToast({
                message: 'Admin was not updated successfully',
                icon: '',
                time: 3000,
                variant: EToastVariant.ERROR
            })
        )
    }
)

export const createOfficeManager = createExceptionAwareAsyncThunk(
    'user/createOfficeAdmin',
    async (args: any) => {
        const response = await axios.post('api/CallCenterAdmin/CreateOfficeAdmin', args)

        dispatch(
            addToast({
                message: 'New admin created successfully',
                icon: successCheckmark,
                time: 3000,
                variant: EToastVariant.SUCCESS
            })
        )

        return response.data.user
    },
    (error) => {
        error.setShowModal(false)
        dispatch(
            addToast({
                message: 'Admin was not created successfully',
                icon: '',
                time: 3000,
                variant: EToastVariant.ERROR
            })
        )
    }
)

export const getNpisFromTaxId = createExceptionAwareAsyncThunk('user/getNpis', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/GetNPIsFromTaxId', args)
    return { taxId: args.taxId, providers: response.data } as ITaxProviderInfo
})

export const getLocationsFromNpi = createExceptionAwareAsyncThunk('user/getLocations', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/GetLocationsFromNpi', args)
    return { npi: args.npi, locations: response.data } as INPILocationsData
})

export const removeHyphensFromTaxIds = (payload: IUser): IUser => {
    // remove hyphens from tax ids inside of payload
    const modifiedTaxIdProviderGroupings: ITaxProviderGrouping[] = payload.taxIdProviderGroupings.map((grouping) => {
        grouping.taxId = grouping?.taxId?.replace(/-/g, '')
        return grouping
    })

    return { ...payload, taxIdProviderGroupings: modifiedTaxIdProviderGroupings }
}

export const getUserDetails = createExceptionAwareAsyncThunk('user/getDetails', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/UserDetails', args)
    return response.data
})

export const disableUser = createExceptionAwareAsyncThunk('user/disable', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/DisableUser', args)

    dispatch(
        addToast({
            message: 'Account disabled successfully',
            icon: successCheckmark,
            time: 3000,
            variant: EToastVariant.SUCCESS
        })
    )

    return response.data
})

export const enableUser = createExceptionAwareAsyncThunk('user/enable', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/EnableUser', args)

    dispatch(
        addToast({
            message: 'Account enabled successfully',
            icon: successCheckmark,
            time: 3000,
            variant: EToastVariant.SUCCESS
        })
    )

    return response.data
})

export const resendInvitation = createExceptionAwareAsyncThunk('user/resendInvitation', async (args: any) => {
    const response = await axios.post('api/CallCenterAdmin/ResendInvitation', args)

    dispatch(
        addToast({
            message: 'Invitation email sent',
            icon: successCheckmark,
            time: 3000,
            variant: EToastVariant.SUCCESS
        })
    )

    return response.data
})

export const validateNPI = createExceptionAwareAsyncThunk('user/validateNPI', async (npi: string) => {
    const response = await axios.post('api/CallCenterAdmin/IsNPIValid', { npi: npi })
    return response.data
})

export const doesEmailAlreadyExist = createExceptionAwareAsyncThunk(
    'user/doesEmailExist',
    async (testEmail: string) => {
        const response = await axios.post('api/CallCenterAdmin/DoesEmailAlreadyExist', { email: testEmail })
        return response.data as IEmailValidation
    }
)

export const getValidAreaCodes = createExceptionAwareAsyncThunk('user/getValidAreaCodes', async () => {
    const response = await axios.post('api/CallCenterAdmin/GetValidAreaCodes')
    return response.data
})
