/*
Portal 1
Identity Provider: AAD
Name: HcP
Roles:
    super admin -> /admins
    call center admin -> /users

Portal 2
Identity Provider: B2C
Name: Provider
Roles: 
    office manager -> /dashboard
    provider -> /dashboard
*/

import { ERoles } from 'models/enums/role'
import { PATH_PAGE } from 'routes/paths'

import {
    awvFormsIcon,
    dashboardIcon,
    multiManCircle,
    pulse8Icon,
    sidebarReportsIcon,
    singleManCircle,
    usersIcon
} from './icons'
import { ESidebarTitle, ISidebarItem } from './SidebarItem'

const sideBarConfig: ISidebarItem[] = [
    {
        title: ESidebarTitle.DASHBOARD,
        path: PATH_PAGE.dashboard,
        icon: dashboardIcon,
        roles: [ERoles.OfficeAdmin, ERoles.Provider]
    },
    {
        title: ESidebarTitle.USERS,
        path: PATH_PAGE.user.list,
        icon: usersIcon,
        roles: [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Quality]
    },
    {
        title: ESidebarTitle.ADMIN,
        path: PATH_PAGE.admin,
        icon: singleManCircle,
        roles: [ERoles.SuperAdmin]
    },
    {
        title: ESidebarTitle.PATIENTS,
        path: PATH_PAGE.member.list,
        alternatePath: PATH_PAGE.member.callCenterAdmin,
        icon: singleManCircle,
        roles: [ERoles.Provider, ERoles.OfficeAdmin]
    },
    {
        title: ESidebarTitle.PATIENTS, //Call Center Admin Members table
        path: PATH_PAGE.member.callCenterAdmin,
        alternatePath: PATH_PAGE.member.list,
        icon: singleManCircle,
        roles: [ERoles.CallCenterAdmin, ERoles.ProviderRep, ERoles.Quality]
    },
    {
        title: ESidebarTitle.AWVForms,
        path: PATH_PAGE.awvForms,
        icon: awvFormsIcon,
        roles: [ERoles.Coding, ERoles.Quality]
    },
    // {
    //     title: 'Admin',
    //     path: PATH_PAGE.admin,
    //     icon: multiManCircle,
    //     roles: [ERoles.CallCenterAdmin, ERoles.ProviderRep]
    // },
    {
        title: ESidebarTitle.REPORTS,
        path: PATH_PAGE.providerReports,
        icon: sidebarReportsIcon,
        roles: [ERoles.OfficeAdmin, ERoles.Provider]
    }
]

export default sideBarConfig
