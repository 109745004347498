import { Routes, Route, Navigate } from 'react-router-dom'

import PageLayout from 'layouts/PageLayout'

import Admin from 'pages/hcp/admin'
import Users from 'pages/hcp/users/list'
import CreateUser from 'pages/hcp/users/create'
import Dashboard from 'pages/provider/dashboard'
import MemberOutreach from 'pages/provider/memberOutreach'
import Pulse8SingleSignOn from 'pages/pulse8SingleSignOn'
import Registration from 'pages/registration'

import { PATH_PAGE } from './paths'
import { FC, Fragment, ReactElement } from 'react'
import { ERoles } from 'models/enums/role'
import MemberInformation from 'pages/hcp/members/information'
import CallCenterAdminList from 'pages/hcp/members/callCenterAdminList'
import { ENavBarItems } from 'pages/hcp/members/information/NavBar/NavBarConfig'
import { ElectronicAnnualWellnessVisitForm } from 'pages/hcp/members/electronicAnnualWellnessVisitForm'
import ReviewableAwvForms from 'pages/coding'
import Page from 'layouts/Page'
import { pageTitle } from 'appInsights/appInsights'

import ProviderReportsPage from 'pages/provider/reports'
import UserEditPage from 'pages/hcp/users/details/UserEditPage'
import UserViewPage from 'pages/hcp/users/details/UserViewPage'
import UserReportPage from 'pages/hcp/users/details/UserReportPage'

interface IRoute {
    path: string
    navigateTo?: string
    element?: ReactElement<any, any> | null
    suppressPageLayout?: boolean
    pageTitle?: string
}

interface RouteProps {
    role: ERoles
}

const routesMap = new Map<ERoles, Array<IRoute>>()

/**
 * Adds the default routes to the given in the parameter.
 * @param newRoutes A list of routes to attach to the default array of routes
 * @returns full list of route (newRoutes + defaultRoutes)
 */
// function buildDefaultRouteWith(newRoutes: IRoute[]): IRoute[] {
//     return _allRoutes.concat(...newRoutes)
// }

const officeAdminRoutes = [
    {
        path: '/provider/',
        navigateTo: PATH_PAGE.dashboard
    }
]

const callCenterAdminRoutes = [
    {
        path: '/hcp/',
        navigateTo: PATH_PAGE.user.list
    }
]

const superAdminRoutes = [
    {
        path: '/hcp/',
        navigateTo: PATH_PAGE.admin
    }
]

const providerRoutes = [
    {
        path: '/provider/',
        navigateTo: PATH_PAGE.dashboard
    }
]

const providerRepRoutes = [
    {
        path: '/hcp/',
        navigateTo: PATH_PAGE.user.list
    }
]

const codingRoutes = [
    {
        path: '/hcp/',
        navigateTo: PATH_PAGE.awvForms
    }
]

routesMap.set(ERoles.OfficeAdmin, officeAdminRoutes)
routesMap.set(ERoles.CallCenterAdmin, callCenterAdminRoutes)
routesMap.set(ERoles.SuperAdmin, superAdminRoutes)
routesMap.set(ERoles.Provider, providerRoutes)
routesMap.set(ERoles.ProviderRep, providerRepRoutes)
routesMap.set(ERoles.Coding, codingRoutes)
routesMap.set(ERoles.Quality, providerRepRoutes)

const routes: IRoute[] = [
    {
        path: '/',
        navigateTo: PATH_PAGE.root,
        pageTitle: pageTitle.Root
    },
    {
        path: PATH_PAGE.root,
        element: null,
        pageTitle: pageTitle.Root
    },
    {
        path: PATH_PAGE.admin,
        element: <Admin />,
        pageTitle: pageTitle.AdminUpdateAWVPDFTemplate
    },
    {
        path: PATH_PAGE.user.view,
        element: <UserViewPage />,
        pageTitle: pageTitle.UserDetails
    },
    {
        path: PATH_PAGE.user.edit,
        element: <UserEditPage />,
        pageTitle: pageTitle.UserDetails
    },
    {
        path: PATH_PAGE.user.reports,
        element: <UserReportPage />,
        pageTitle: pageTitle.UserReports
    },
    {
        path: PATH_PAGE.user.create,
        element: <CreateUser />,
        pageTitle: pageTitle.UsersNewUser
    },
    {
        path: PATH_PAGE.user.list,
        element: <Users />,
        pageTitle: pageTitle.UsersSummary
    },
    {
        path: PATH_PAGE.dashboard,
        element: <Dashboard />,
        pageTitle: pageTitle.Incentives
    },
    {
        path: PATH_PAGE.memberOutreach,
        element: <MemberOutreach />,
        pageTitle: pageTitle.PatientOutreach
    },
    {
        path: PATH_PAGE.pulse8SpecificNpi,
        element: <Pulse8SingleSignOn />,
        suppressPageLayout: true,
        pageTitle: pageTitle.Pulse8SpecificNPI
    },
    {
        path: PATH_PAGE.pulse8,
        element: <Pulse8SingleSignOn />,
        suppressPageLayout: true,
        pageTitle: pageTitle.Pulse8
    },
    {
        path: PATH_PAGE.registration,
        element: <Registration />,
        suppressPageLayout: true,
        pageTitle: pageTitle.Registration
    },
    {
        path: PATH_PAGE.member.list,
        // element: <Members />
        element: <CallCenterAdminList />,
        pageTitle: pageTitle.PatientsSummary
    },
    {
        path: PATH_PAGE.member.memberInformation,
        element: <MemberInformation />,
        pageTitle: pageTitle.PatientsPersonal
    },
    {
        path: PATH_PAGE.member.memberAwvInformation,
        element: <MemberInformation tab={ENavBarItems.AWV} />,
        pageTitle: pageTitle.PatientsAWV
    },
    {
        path: PATH_PAGE.member.memberGapsInCare,
        element: <MemberInformation tab={ENavBarItems.GAPSINCARE} />,
        pageTitle: pageTitle.PatientsGapsInCare
    },
    {
        path: PATH_PAGE.member.callCenterAdmin,
        element: <CallCenterAdminList />,
        pageTitle: pageTitle.PatientsSummary
    },
    {
        path: PATH_PAGE.member.electronicAnnualWellnesVisitForm,
        element: <ElectronicAnnualWellnessVisitForm readOnly={false} />,
        suppressPageLayout: true,
        pageTitle: pageTitle.PatientsAWVEditWebForm
    },
    {
        path: PATH_PAGE.member.viewElectronicAnnualWellnesVisitForm,
        element: <ElectronicAnnualWellnessVisitForm readOnly={true} />,
        suppressPageLayout: true,
        pageTitle: pageTitle.PatientsAWVViewWebForm
    },
    {
        path: PATH_PAGE.awvForms,
        element: <ReviewableAwvForms />,
        pageTitle: pageTitle.SubmittedAWVForms
    },
    {
        path: PATH_PAGE.providerReports,
        pageTitle: pageTitle.ProviderReportsPage,
        element: <ProviderReportsPage />
    }
]
const Router: FC<RouteProps> = ({ role }) => {
    // gets the routes for the role or empty array if not found
    const routesForRole: IRoute[] = routesMap.get(role) || []

    return (
        <Fragment>
            <Routes>
                {routesForRole.concat(routes).map((route, index) => (
                    <Route
                        path={route.path}
                        element={
                            route.navigateTo ? (
                                <Navigate to={route.navigateTo} />
                            ) : route.suppressPageLayout ? (
                                <Page pageTitle={route.pageTitle}>{route.element}</Page>
                            ) : (
                                <Page pageTitle={route.pageTitle}>
                                    <PageLayout>{route.element}</PageLayout>
                                </Page>
                            )
                        }
                        key={index}
                    />
                ))}
            </Routes>
        </Fragment>
    )
}

export default Router
